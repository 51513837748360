import React from 'react'

const Navbar = () => {
    return (

        <nav class="  bg-black lg:px-[32px] h-[54px] lg:h-[84px]">
            <div class="flex items-center justify-between  mx-auto lg:py-5 p-2 h-full w-full">
                <a href="/" class="flex items-center h-full">
                    <img src={require('../assets/images/logo-var1.png')} className="w-full h-full" alt="Flowbite Logo" />
                </a>

                <div className='flex flex-row gap-3  h-full' >
                    <a href='#appstore-slider' className='flex-row flex rounded-full bg-orange bg-black p-3 md:m-0 h-full border-2'style={{ borderColor: 'white' }} >
                    <img src={require('../assets/images/apple.png')} className='sm:mr-2  h-[16px] w-[16px] sm:w-[24px] sm:h-[24px] self-center' alt='' />
                        <p className=' text-white self-center font-bold font-sans sm:block hidden ' >APPLE'a Yükle</p>
                    </a>
                    <a href='#google-slider' className='flex-row flex rounded-full bg-orange bg-black p-3 mr-3 lg:m-0  border-2' style={{ borderColor: 'white' }} >
                    <img src={require('../assets/images/android.png')} className='sm:mr-2  h-[16px] w-[16px] sm:w-[24px] sm:h-[24px] self-center' alt='' />
                        <p className=' text-white self-center font-bold font-sans hidden sm:block ' >ANDROID'e Yükle</p>
                    </a>
                </div>
            </div>
        </nav>

    )
}

export default Navbar