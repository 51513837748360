import { useState } from 'react'
import Navbar from '../components/Navbar'
import Slider from '../components/Slider'
import appleIcon from '../assets/images/apple.png'
import playstoreIcon from '../assets/images/android.png'
import androidVideo from '../assets/videos/android.mp4'
import ios from '../assets/videos/ios.mp4'
import app from '../assets/images/app.png'
import and from '../assets/images/and.png'

const Home = () => {
  
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };

  const [activeTab, setActiveTab] = useState('android');
  const [videoSource, setVideoSource] = useState(androidVideo)


  const handler = (e) => {
    setActiveTab(activeTab === 'android' ? 'ios' : 'android')
    setVideoSource(videoSource === androidVideo ? ios : androidVideo)
  }

  return (
    <div className='overflow-hidden' >
      <Navbar />


      <section className='flex flex-col items-center h-[30.26vw] ' id='section-1'></section>

      <section className='flex flex-col items-center justify-center bg-[#1D1D1D] sm:px-3 ' id='section-2'>
        <div className='relative  mb-3' >
          <img src={require('../assets/images/section-2-img-1.png')} className='' alt='' />
        </div>
        <div className='lg:mb-8 sm:mt-16 mt-5' >
          <h1 className='lg:text-6xl text-3xl text-center  lg:mb-8  text-white text-transparent ' >BETNANO İLE GÜVENİLİR BAHİS KOLAY ÖDEME</h1>
          <p className='text-white sm:px-40 text-center lg:mb-11'>Betnano güvenlik ve oyun çeşitliliği açısından Türkiye’nin en önde gelen bahis ve oyun sitesidir. Geniş oyun yelpazesi, yüksek oranlı ve canlı bahis, canlı casino ile gerçek casino deneyimini Betnano’da bulacaksınız. Valeyboldan futbola, basketboldan tenise birçok spor müsabakası Betnano’da. Uygulamayı hemen indirin dünya genelindeki müsabakalara, canlı casinoya ve 100’lerce güncel oyuna hemen erişin!</p>
        </div>
      </section>

      <section className='bg-gradient-to-b from-[#1D1D1D] to-white lg:mb-20'>
        <div className='p-4 lg:px-32' >
          <div className='grid lg:grid-cols-4 grid-cols-2 bg-[#FACE52] gap-3 lg:p-16 p-5 rounded-3xl '>
            <div className='lg:max-w-[213px] max-w-[150px] mx-auto ' >
              <div className='flex items-center justify-center mb-5' >
                <img src={require('../assets/images/Rectangle 18118.png')} />
              </div>
              <h3 className='text-black font-bold text-center mb-5 sm:text-2xl' >Oyun ve Ödüller</h3>
              <p className='text-black text-center'>Heyecan dolu yarışmalara katılarak milyonlara varan ödül havuzlarından faydalanın.</p>
            </div>

            <div className='lg:max-w-[213px] max-w-[150px] mx-auto' >
              <div className='flex items-center justify-center mb-5' >
                <img src={require('../assets/images/Rectangle 18116.png')} />
              </div>
              <h3 className='text-black font-bold text-center mb-5  sm:text-2xl' >Maksimum Güvenlik</h3>
              <p className='text-black text-center '>Betnano’da bütün işlemlerinizi güvenle gerçekleştirin. </p>
            </div>

            <div className='lg:max-w-[213px] max-w-[150px] mx-auto' >
              <div className='flex items-center justify-center mb-5' >
                <img src={require('../assets/images/Rectangle 18119.png')} />
              </div>
              <h3 className='text-black font-bold text-center mb-5  sm:text-2xl' >Yüksek Bahis Oranları</h3>
              <p className='text-black text-center'>Yüksek bahis oranlarıyla kazanç potansiyelinizi artırın!</p>
            </div>

            <div className='lg:max-w-[213px] max-w-[150px] mx-auto' >
              <div className='flex items-center justify-center mb-5' >
                <img src={require('../assets/images/Rectangle 18117.png')} />
              </div>
              <h3 className='text-black font-bold text-center mb-5  sm:text-2xl' >Kolay Kullanım</h3>
              <p className='text-black text-center'>Betnano’da istediğiniz zaman istediğiniz yerden, webden, cepten ya da tabletten bahis yapın.</p>
            </div>
          </div>
        </div>

      </section>

      <section className=' relative overflow-hidden  bg-white' id='google-slider'>
          <div className='lg:mb-10 mb-5 px-5' >
               <div className='px-[45%] sm:mb-16 mb-6'>
                <div className='flex-row flex rounded-full bg-orange bg-white p-3 md:m-0 h-full border-2 items-center justify-center'style={{ borderColor: '#FBCA34' }}>
                    <img src={require('../assets/images/Vector(11).png')} className='sm:mr-2  h-[16px] w-[16px] sm:w-[24px] sm:h-[24px] ' alt='' />
                        <p className=' text-[#FBCA34] font-bold font-sans sm:block hidden text-center ' >Google Play</p>
                        </div></div>
        
          <h3 className='lg:text-5xl text-3xl text-center font-semibold text-black' >BET <span className='text-[#FACE52]'>NANO</span> NASIL İNDİRİLİR?</h3>
       <p className='text-black text-center sm:text-2xl mt-5'>Betnano’yu android telefonunuz ya da tabletinize yüklemek için aşağıdaki adımları izleyin.</p>
        </div>
        <div className='lg:px-36 px-5 ' >
          <Slider platform='android' />
        </div>
      </section>

      <section className='lg:mb-24 mb-10 overflow-hidden relative  bg-white' id='appstore-slider'>
        <div className='lg:mb-10 mb-5 px-5 mt-20' >
        <div className='px-[45%] sm:mb-16 mb-6'>
                <div className='flex-row flex rounded-full bg-orange bg-white p-3 md:m-0 h-full border-2 items-center justify-center'style={{ borderColor: '#FBCA34' }}>
                    <img src={require('../assets/images/Vector(12).png')} className='sm:mr-2  h-[16px] w-[16px] sm:w-[24px] sm:h-[24px] ' alt='' />
                        <p className=' text-[#FBCA34] font-bold font-sans sm:block hidden text-center ' >App Store</p>
                        </div> </div>
        <h3 className='lg:text-5xl text-3xl text-center font-semibold text-black' >BET <span className='text-[#FACE52]'>NANO</span> NASIL İNDİRİLİR?</h3>  
        <p className='text-black text-center sm:text-2xl mt-5'>Betnano’yu Apple telefonunuz ya da tabletinize yüklemek için aşağıdaki adımları izleyin.</p>
        </div>
        <div className='lg:px-36 px-5 ' >
          <Slider platform='ios' />
        </div>
      </section>

      <section className='bg-gradient-to-b from-[#A57700] to-[#FACE52] flex flex-col' >
      <h1 className='lg:text-6xl text-3xl text-center  lg:mb-5  text-white text-transparent sm:mt-20 mt-6' >BETNANO’YU VIDEO ile İNDİRİN!</h1>
      <p className='text-white text-center sm:text-2xl lg:mb-10 px-4'>Betnano’yu Apple telefonunuz ya da tabletinize yüklemek için aşağıdaki adımları izleyin.</p>


        <div className='m-auto flex p-1 rounded-2xl  sm:mb-10 '>

          <div
            className={`flex justify-center p-3 rounded-2xl ${activeTab === 'android' ? '' : ''}`}
            onClick={handler}>
            <img
              src={and}
              className='mr-3 text-white sm:h-[40px] sm:w-[135px]'
            />         
          </div>


          <div
            className={`flex justify-center p-3 rounded-full ${activeTab === 'ios' ? '' : ''}`}
            onClick={handler} >
            <img
              src={app}
              className=' sm:h-[40px] sm:w-[135px]'
            />
          </div>
        </div>
       
        <div className='mb-10 px-5' >
            <Player source={videoSource} key={activeTab}/>
          </div>

      </section>

      <footer className='bg-[#1D1D1D] h-auto ' >
         <div className='sm:grid grid-cols-2 pt-20'>
          
          <div className='self-center'>
          <p className='text-white sm:ml-[20%] sm:pt-0 pt-6'>
          Betnano'da öncelik her zaman kullanıcı memnuniyeti! En kaliteli bahis deneyimini sunmak için güçlü teknolojik çözümler ve güçlü altyapımızı kullanıyoruz.
          <br />
          <br />
          Hızlı ve güvenli kayıt işlemleri, yüzlerce spor bahisi ve casino oyun seçeneği,
          hoş geldin bonusu ve düzenli promosyonlar, canlı yayın seçenekleri
          yüksek oranlar ve kazanç fırsatları, spor bahisleri, canlı bahis, casino, poker, at yarışları ve daha fazlası...
          <br />
          <br />
          Hemen siz de Betnano’ya gelin ve en kaliteli bahis deneyimini size özel sınırsız ayrıcalıkla yaşayın!
          </p>
          </div>
          <div className='m-auto '>
          <img src={require('../assets/images/section-6-img-1.png')} className='' alt='' />
          </div>
         </div>
     
         <div className='md:ml-[10%] pb-4 '>
         <img src={require('../assets/images/logo-var1.png')} className='self-center mb-4' alt='' />
            
             <div className='gap-5 mb-5 flex flex-cols-2 '>
              <div className='max-w-100 flex flex-col-3   gap-2 sm:gap-5  items-center justify-center'>
                <img src={require('../assets/images/Vector(6).png')} className='' alt='' />
                <img src={require('../assets/images/Vector(7).png')} className='' alt='' />
                <img src={require('../assets/images/Vector(8).png')} className='' alt='' />
                </div>     
                <p className='text-[#DDDDDD] self-center text-center '>© 2023 BetNano</p>

            </div>
          </div>
      </footer>

    </div>
  )
}

export default Home

const Player = ({ key, source }) => {
  return (
    <video key={key} class="lg:w-3/4 border m-auto border-gray-200 rounded-lg dark:border-gray-700" controls>
      <source src={source} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  )
}
